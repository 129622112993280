import { format as dateFnsFormat, getUnixTime, isValid, parseISO, toDate } from 'date-fns';
import { Fragment, ReactNode, cloneElement, isValidElement } from 'react';
import { isProd } from './environment';

export type JsonObject = Record<string, unknown>;

export type FlatObject = Record<string, string>;

export type LoadState = 'unloaded' | 'loading' | 'loaded';

export type ViewState = 'view' | 'edit' | 'add';

export type UiOption = {
  label: string;
  value: string;
  match?: boolean;
  disabled?: boolean;
  meta?: Record<string, unknown>;
};

export enum DateFormat {
  MEDIUM = 'yyyy-MM-dd HH:mm',
  FULL = 'yyyy-MM-dd HH:mm:ss',
}

export type NiceDate = {
  date: string;
  time: string;
  timeFull: string;
  formatted: string;
  dateObj: Date | null;
};

export type DateVal = string | Date | number | null;

export type DateRangeValue = [Date | null, Date | null];

export const LAST_TIMESTAMP = 9999999999999;

export const AWS_REGION_OPTS: UiOption[] = [
  { label: 'US East (Ohio)', value: 'us-east-2' },
  { label: 'US East (N. Virginia)', value: 'us-east-1' },
  { label: 'US West (N. California)', value: 'us-west-1' },
  { label: 'US West (Oregon)', value: 'us-west-2' },
];

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getRandomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getIdFromLabel = (label: string): string =>
  String(label)
    .replace(/ +/g, '-')
    .replace(/[^A-Za-z0-9-]+/, '')
    .toLowerCase();

export const getDateObj = (val: DateVal): Date | null => {
  if (!val) {
    return null;
  }

  if (val instanceof Date) {
    return val;
  }

  let dateObj: Date | null = null;

  if (String(val).match(/^[0-9]+$/) || typeof val === 'number') {
    const valSeconds = String(val).length === 16 ? Math.floor(Number(val) / 1000) : Number(val); // convert ms timestamps to s timestamps
    dateObj = toDate(valSeconds);
  } else {
    dateObj = parseISO(val);
  }

  if (isValid(dateObj)) {
    return dateObj;
  }

  return null;
};

export const getTimestamp = (val: DateVal): number | null => {
  const dateObj = getDateObj(val);
  if (!dateObj) {
    return null;
  }

  return getUnixTime(dateObj);
};

export const getNiceNumber = (val: number, options?: { decimalPlaces?: number }): string => {
  const { decimalPlaces } = options || {};

  const safeD = typeof decimalPlaces === 'undefined' ? 0 : Number(decimalPlaces);

  // toLocaleString adds the thousands separator in the default locale, and the min/max options limit the decimal places
  const localeOpts = {
    minimumFractionDigits: safeD,
    maximumFractionDigits: safeD,
  };

  const localVal = val.toLocaleString(undefined, localeOpts);

  // prevent display of negative sign when value is zero (i.e. when -0.34 is truncated to -0)
  if (localVal.match(/^-0.?0*$/)) {
    return '0';
  }

  return localVal;
};

export const getNiceDate = (val: DateVal, format?: DateFormat): NiceDate => {
  const niceDate: NiceDate = { date: '', time: '', timeFull: '', formatted: '', dateObj: null };
  const dateObj = getDateObj(val);

  if (!dateObj) {
    return niceDate;
  }

  niceDate.dateObj = dateObj;
  niceDate.date = dateFnsFormat(dateObj, 'yyyy-MM-dd');
  niceDate.time = dateFnsFormat(dateObj, 'HH:mm');
  niceDate.timeFull = dateFnsFormat(dateObj, 'HH:mm:ss');
  niceDate.formatted = dateFnsFormat(dateObj, format || DateFormat.MEDIUM);

  return niceDate;
};

export const getFirstLastName = (name: string): { first: string; last: string } => {
  const nameParts = name.split(' ');
  const first = nameParts.shift() || '';
  return { first, last: nameParts.join(' ') };
};

export const joinReactNodes = (nodes: ReactNode[], glue: ReactNode): ReactNode[] => {
  const joinedNodes: ReactNode[] = [];
  const GlueEl = isValidElement(glue) ? glue : <Fragment>{glue}</Fragment>;

  nodes.forEach((node, index) => {
    const NodeEl = isValidElement(node) ? node : <Fragment>{node}</Fragment>;
    joinedNodes.push(cloneElement(NodeEl, { key: `node-${index}` }));
    if (index < nodes.length - 1) {
      joinedNodes.push(cloneElement(GlueEl, { key: `glue-${index}` }));
    }
  });
  return joinedNodes;
};

export const getPortalUrl = (signedIn?: boolean): string => {
  let domain = 'portal.surepath.ai';

  if (!isProd()) {
    const [, environment] = window.location.hostname.split('.');

    if (['dev', 'stage', 'local'].includes(environment)) {
      domain = `portal.${environment}.surepath.ai`;
    }
  }

  let portalUrl = `https://${domain}`;

  if (signedIn) {
    portalUrl = `${portalUrl}?fas=1`;
  }

  return portalUrl;
};
